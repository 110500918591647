//@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css);
//@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@700&display=swap');

//@font-face {
//    font-family: 'MalgunGothic';
//    src: url("fonts/malgun.ttf") format("truetype");
//    font-weight: normal;
//    font-style: normal;
//}
//
//@font-face {
//    font-family: 'MalgunGothic';
//    src: url("fonts/malgun-bold.ttf") format("truetype");
//    font-weight: bold;
//    font-style: normal;
//}

:root {
    --sub-menu-background-color: #eaebec;
}

.sortable-helper {
    z-index: 1000;
}

.text-center {
    text-align: -webkit-center;
    text-align: center;
}

.cursor-pointer {
    cursor: pointer;
}

.font-mono {
    font-family: "Roboto Mono", monospace !important;
}

//*::-webkit-scrollbar {
//    width: 12px;
//    height: 12px;
//}
//
//*::-webkit-scrollbar-thumb {
//    background-color: #cccccc;
//    border-radius: 7px;
//    background-clip: padding-box;
//    border: 3px solid transparent;
//}
//
//*::-webkit-scrollbar-thumb:hover {
//    background-color: #929292;
//}


.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
    outline: none;

    :hover, :focus {
        color: var(--color-main);
        text-decoration: none;
    }
}

.modal {
    padding-left: 0 !important;
}

.guide-modal {
    margin-right: 0 !important;
    margin-left: 0 !important;
    max-width: 100% !important;
    padding-left: 0 !important;
}

.transparent-modal {
    .modal-content {
        background-color: unset;
    }
}

.rounded-modal > .modal-content {
    border-radius: 1rem !important;
}

.white-space-pre {
    white-space: pre;
}

.white-space-pre-wrap {
    white-space: pre-wrap;
}

.rounded-image{
    border: 1px solid black;
    border-radius: 1em;
}

.non-transparent {
    opacity: 1 !important;
}

.mr-0 {
    margin-right: 0 !important
}

.mr-1 {
    margin-right: .375rem !important
}

.mr-2 {
    margin-right: .75rem !important
}

.mr-3 {
    margin-right: 1.5rem !important
}

.mr-4 {
    margin-right: 2.25rem !important;
}

.mr-5 {
    margin-right: 4.5rem !important;
}

.ml-0 {
    margin-left: 0 !important
}

.ml-1 {
    margin-left: .375rem !important
}

.ml-2 {
    margin-left: .75rem !important
}

.ml-3 {
    margin-left: 1.5rem !important
}

.ml-4 {
    margin-left: 2.25rem !important;
}

.ml-5 {
    margin-left: 4.5rem !important;
}


.pl-0 {
    padding-left: 0 !important
}

.pl-1 {
    padding-left: .375rem !important
}

.pl-2 {
    padding-left: .75rem !important
}

.pl-3 {
    padding-left: 1.5rem !important
}

.pl-4 {
    padding-left: 2.25rem !important;
}

.pl-5 {
    padding-left: 4.5rem !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-1 {
    padding-right: .375rem !important
}

.pr-2 {
    padding-right: .75rem !important
}

.pr-3 {
    padding-right: 1.5rem !important
}

.pr-4 {
    padding-right: 2.25rem !important;
}

.pr-5 {
    padding-right: 4.5rem !important;
}


.btn-rounded {
    border-radius: 50rem !important;
}

//.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
//    font-weight: 500;
//}

.form-check-label {
    vertical-align: middle;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
    //.floating-label {
    //    display: inline;
    //}
    .floating-placeholder {
        color: transparent !important;
    }
}

//.form-floating > .form-control:focus ~ label {
//    //.floating-placeholder {
//    //    color: #;
//    //}
//
//    .floating-label {
//        display: inline;
//    }
//
//}

.form-floating > .form-control:not(:placeholder-shown) ~ label {
    .floating-label {
        display: inline !important;
    }

}

.form-floating > .form-control:not(:focus) ~ label {
    .floating-label {
        display: none;
    }

}

.widget-icon {
    color: #4575fb;
    font-size: 20px;
    background-color: rgba(69, 117, 251, 0.25);
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 3px;
    display: inline-block;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

body {
    overflow: hidden !important;
}
